import { memo, useCallback, useEffect, useState } from "react";
import { MetaDataService, HeldScenesMetadata } from "./MetadataService";
import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";
import ImageListItemBar from "@material-ui/core/ImageListItemBar";
import ListSubheader from "@material-ui/core/ListSubheader";
import IconButton from "@material-ui/core/IconButton";
import InfoIcon from "@material-ui/icons/Info";
import { MintPopup } from "./MintPopup";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { WalletContextState } from "@solana/wallet-adapter-react";

export interface ISceneGridProps {
  heldSceneMetadata: HeldScenesMetadata[];
  walletState: WalletContextState | null;
  walletPubKey: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.default,
    },
    imageList: {
      width: "100%",
      height: "100%",
    },
    ImageListItem: {
      maxHeight: "300px",
      maxWidth: "300px",
      cursor: "pointer",
      // width: "400px",
      // height: "400px",
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)",
    },
  })
);

const SceneGrid = ({
  heldSceneMetadata,
  walletPubKey,
  walletState,
}: ISceneGridProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [sceneHeldMetadata, setSceneHeldMetadata] =
    useState<HeldScenesMetadata | null>(null);
  const classes = useStyles();

  useEffect(() => {
    if (heldSceneMetadata && sceneHeldMetadata) {
      const sceneAlreadyLoaded = sceneHeldMetadata.scene;
      for (let x = 0; x < heldSceneMetadata.length; x++) {
        const metadata = heldSceneMetadata[x];
        if (metadata.scene === sceneAlreadyLoaded) {
          console.log("updating held scene metadata");
          setSceneHeldMetadata(metadata);
        }
      }
    }
  }, heldSceneMetadata);

  const mintPopup = (
    <MintPopup
      setScenesHeldMetadata={setSceneHeldMetadata}
      heldSceneMetadata={sceneHeldMetadata}
      walletState={walletState}
      walletPubKey={walletPubKey}
    />
  );

  return (
    <div className={classes.root}>
      <ImageList
        rowHeight={500}
        cols={4}
        gap={16}
        className={classes.imageList}
      >
        {mintPopup}
        {heldSceneMetadata.map((item: HeldScenesMetadata) => (
          <ImageListItem
            key={item.scene}
            cols={2}
            className={classes.ImageListItem}
            onClick={() => {
              setSceneHeldMetadata(item);
            }}
          >
            <LazyLoadImage
              alt={item.scene + ""}
              height={300}
              src={item.image} // use normal <img> attributes as props
              width={300}
            />
            {/* <img src={item.image} alt={item.scene + ""} /> */}
            <ImageListItemBar
              title={"Scene #" + item.scene}
              subtitle={<span>{item.mints.length} NFTs </span>}
            />
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};

export { SceneGrid };
