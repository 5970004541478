import {
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@material-ui/core";
import {
  createStyles,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import { web3 } from "@project-serum/anchor";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import {
  default as React,
  memo,
  useCallback,
  useEffect,
  useState,
} from "react";
import { JsxEmit } from "typescript";
import { WalletWrapper } from "../Wallet/WalletWrapper";
import { ApiService } from "./ApiService";
import { ConnectionService } from "./ConnectionService";
import { MetaDataService, HeldScenesMetadata } from "./MetadataService";
import { MintService } from "./MintList";
import { SceneGrid } from "./SceneGrid";
import { StateService } from "./StateService";
import { TokenMetadata, TokenMetadataService } from "./TokenMetadata";

const { TOKEN_PROGRAM_ID } = require("@solana/spl-token");

export interface IProductHeroProps {}

export interface IWalletConnection {
  walletPubKey: string;
  isWalletConnected: boolean;
  walletState: WalletContextState | null;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    centerItem: {
      align: "center",
      justify: "center",
      alignItems: "center",
    },
  })
);

// eslint-disable-next-line no-empty-pattern
const SceneView = memo(({}: IProductHeroProps): JSX.Element => {
  const classes = useStyles();
  const [walletConnection, setWalletConnection] = useState<IWalletConnection>({
    walletPubKey: "",
    isWalletConnected: false,
    walletState: null,
  });
  const [scenesHeldMetadata, setScenesHeldMetadata] = useState<
    HeldScenesMetadata[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    StateService.setScenesUpdatedCallback(setScenesHeldMetadata);
    return () => {
      StateService.shutdown();
    };
  }, []);

  // Remove previous wallets nfts
  useEffect(() => {
    if (!walletConnection.isWalletConnected) {
      setScenesHeldMetadata([]);
    }
    return () => {};
  }, [walletConnection.isWalletConnected]);

  // On new connection, grab all of the nfts
  useEffect(() => {
    async function doStuff() {
      if (
        walletConnection.isWalletConnected &&
        walletConnection.walletPubKey !== ""
      ) {
        setIsLoading(true);
        await StateService.getMintAccounts(walletConnection.walletPubKey);
        setIsLoading(false);
      }
    }

    doStuff();
    return () => {};
  }, [walletConnection.walletPubKey, walletConnection.isWalletConnected]);

  // On nft merging occurance, then re-render
  // I'd need to set the entire wallet mints but I could

  const onWalletConnect = useCallback((walletConnection: IWalletConnection) => {
    setWalletConnection({ ...walletConnection });
  }, []);

  const sceneGrid = (
    <SceneGrid
      heldSceneMetadata={scenesHeldMetadata}
      walletState={walletConnection.walletState}
      walletPubKey={walletConnection.walletPubKey}
    />
  );

  // const classes = useStyles();
  const videoToPlay = !walletConnection.isWalletConnected ? (
    <>
      <Container style={{ width: "70%", textAlign: "center" }}>
        <Typography
          style={{ color: "white !important", paddingBottom: "16px" }}
          color="secondary"
          variant="body1"
        >
          Welcome explorer to the first phase of merging! 💫 You can now merge
          two timelines of eight.{" "}
        </Typography>

        <Typography
          style={{ color: "white !important", paddingBottom: "16px" }}
          color="secondary"
          variant="body1"
        >
          <a
            style={{ color: "white" }}
            href="https://infinitylabsnft.com/merging-faq"
          >
            View our FAQ here
          </a>
        </Typography>
      </Container>
      <video
        style={{ marginBottom: "48px" }}
        autoPlay
        width="500"
        height="281"
        muted
        loop
      >
        <source src="montage.mp4" type="video/mp4" />
      </video>
    </>
  ) : (
    <span />
  );
  return (
    <div>
      <Grid
        alignContent="center"
        alignItems="center"
        justifyContent="center"
        direction="column"
        container
        style={{ marginTop: "48px" }}
      >
        {videoToPlay}
        <Grid style={{ marginBottom: "32px" }} item>
          <WalletWrapper onWalletConnect={onWalletConnect} />
        </Grid>
        <Grid item>
          {isLoading ? (
            <CircularProgress />
          ) : walletConnection.isWalletConnected ? (
            sceneGrid
          ) : (
            <span />
          )}
        </Grid>
      </Grid>
    </div>
  );
});

export { SceneView };
