import MuiAppBar from "@material-ui/core/AppBar";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React from "react";

const styles = (theme) => ({
  root: {
    color: theme.palette.common.white,
    backgroundColor: "transparent",
    webkitBackdropFilter: "blur(10px)",
    backdropFilter: "blur(10px)",
    borderBottom: "1px solid hsla(0,0%,100%,.2)",
  },
});

function AppBar(props) {
  return <MuiAppBar elevation={0} position="static" {...props} />;
}

AppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AppBar);
