import { web3 } from "@project-serum/anchor";

class ConnectionService {
  private connection: web3.Connection;
  constructor() {
    const clusterUrl =
      process.env.NODE_ENV === "production"
        ? "https://mainnet.helius-rpc.com/?api-key=d1ebd450-1797-4b39-b0d5-bec2a4bc11a5"
        : //? "https://solana-mainnet.g.alchemy.com/v2/hnIvp_uv5_OBS02WjXajVZI_PvdK_0VB"
          "https://api.devnet.solana.com";
    console.log("#helius API");
    this.connection = new web3.Connection(clusterUrl, "confirmed");
  }

  public getConnection() {
    return this.connection;
  }
}

const connectionService = new ConnectionService();
export { connectionService as ConnectionService };
