import originalFetch from "cross-fetch";
const fetch = require("fetch-retry")(originalFetch);

class ApiService {
  private baseUrl: string = "";
  private apiBase = "v1/api/";
  private mergingNftsApiPath = "held/";
  constructor() {
    if (process.env.NODE_ENV === "production") {
      this.baseUrl = "https://api.infinitylabsnft.com/";
    } else {
      this.baseUrl = "http://localhost:3000/";
    }
    console.log(`using api url ${this.baseUrl}`);
  }

  public async getMergingNfts(originWallet: string) {
    const url =
      this.baseUrl + this.apiBase + this.mergingNftsApiPath + originWallet;
    const mintsThatUserIsMerging: string[] = await (
      await fetch(url, {
        retries: 3,
        retryDelay: 1000,
      })
    ).json();

    return mintsThatUserIsMerging;
  }
}

const apiService = new ApiService();
export { apiService as ApiService };
