import { TOKEN_PROGRAM_ID } from "@solana/spl-token";
import { PublicKey } from "@solana/web3.js";
import { ApiService } from "./ApiService";
import { ConnectionService } from "./ConnectionService";
import { MetaDataService, HeldScenesMetadata } from "./MetadataService";
import { MintService } from "./MintList";
import { NftsToMerge } from "./MintPopup";
import { TokenMetadata, TokenMetadataService } from "./TokenMetadata";

class StateService {
  private heldScenesChangedCallback!: (
    walletMints: HeldScenesMetadata[]
  ) => void;
  private sceneNumberToHeldScenesMetadata = new Map<
    number,
    HeldScenesMetadata
  >();
  private readonly MILLIS_IN_MINUTE = 60000;
  private mintsCurrentlyMerging = new Set<string>();
  private mintsSentToMergingTimes = new Map<string, number>();
  // Mints already merged will have their name changed?
  private mintsAlreadyMerged = new Set<string>();
  private mintsAlreadyBurned = new Set<string>();
  private currentMintWallet: string = "";

  constructor() {
    this.shutdown();
    setInterval(
      () => this.checkIfMergingFinished(),
      this.MILLIS_IN_MINUTE * 0.75
    );
  }

  private async checkIfMergingFinished() {
    if (
      this.mintsCurrentlyMerging.size === 0 ||
      !this.currentMintWallet ||
      this.currentMintWallet === ""
    ) {
      return;
    }

    console.log("fetching more accuratre info");

    this.getMintAccounts(this.currentMintWallet);
  }

  public isMintMerging(mint: string) {
    return this.mintsCurrentlyMerging.has(mint);
  }

  public isMintBurned(mint: string) {
    return this.mintsAlreadyBurned.has(mint);
  }

  public isMintMerged(mint: string) {
    return this.mintsAlreadyMerged.has(mint);
  }

  /**
   * We have a transaction confirmation that nfts were sent to merge.
   *
   * Update our front end state
   * @param nftsToMerge
   */
  public sentNftsToMerge(nftsToMerge: NftsToMerge) {
    this.mintsCurrentlyMerging.add(nftsToMerge.tokenToKeep!.mint);
    this.mintsSentToMergingTimes.set(nftsToMerge.tokenToKeep!.mint, Date.now());
    this.mintsAlreadyMerged.add(nftsToMerge.tokenToKeep!.mint);
    this.mintsAlreadyBurned.add(nftsToMerge.tokenToBurn!.mint);

    const heldSceneMetadata = this.sceneNumberToHeldScenesMetadata.get(
      MetaDataService.getSceneNumberForMint(
        nftsToMerge!.tokenToBurn!.mint
      ) as number
    );
    if (!heldSceneMetadata || !heldSceneMetadata) {
      console.log("sent nfts that we don't know about");
      return;
    }

    heldSceneMetadata.mints = heldSceneMetadata.mints.filter(
      (tokenMetadata) => {
        return tokenMetadata.mint !== nftsToMerge.tokenToBurn?.mint;
      }
    );

    const heldScenesMetadata = Array.from(
      this.sceneNumberToHeldScenesMetadata.values()
    );
    // Sort numerically
    heldScenesMetadata.sort((a, b) => {
      return a.scene - b.scene;
    });
    this.heldScenesChangedCallback(heldScenesMetadata);
  }

  /**
   * Set the callback that should make everything re-render
   */
  public setScenesUpdatedCallback(
    heldScenesChangedCallback: (walletMints: HeldScenesMetadata[]) => void
  ) {
    this.heldScenesChangedCallback = heldScenesChangedCallback;
  }

  public shutdown() {
    this.heldScenesChangedCallback = (amount) =>
      (this.sceneNumberToHeldScenesMetadata = new Map());
  }

  /**
   * Somebody just connected a new wallet and we recieved the most up to date data
   *
   * Or we waited a minute
   */
  private initScenesHeldMetadata(heldScenesMetada: HeldScenesMetadata[]) {
    this.sceneNumberToHeldScenesMetadata = new Map();
    for (const heldSceneMetadata of heldScenesMetada) {
      this.sceneNumberToHeldScenesMetadata.set(
        heldSceneMetadata.scene,
        heldSceneMetadata
      );
    }

    heldScenesMetada.sort((a, b) => {
      return a.scene - b.scene;
    });

    this.heldScenesChangedCallback(heldScenesMetada);
  }

  /**
   * Called when a wallet is first connected, and every minute to get up to date info
   * @param walletPubKey
   */
  public async getMintAccounts(walletPubKey: string) {
    this.currentMintWallet = walletPubKey;
    const connection = ConnectionService.getConnection();
    let publicKey = new PublicKey(walletPubKey);

    const mintsAlreadyMergingPromise = ApiService.getMergingNfts(walletPubKey);

    let response = await connection.getParsedTokenAccountsByOwner(publicKey, {
      programId: TOKEN_PROGRAM_ID,
    });

    const walletMints: string[] = response.value
      .filter(
        (accInfo) =>
          accInfo.account.data.parsed.info.tokenAmount.uiAmount === 1 &&
          MintService.isInMintList(accInfo.account.data.parsed.info.mint)
      )
      .map((accInfo) => {
        return accInfo.account.data.parsed.info.mint;
      });

    let mintsAlreadyMerging: string[] = [];
    try {
      mintsAlreadyMerging = await mintsAlreadyMergingPromise;
    } catch (error) {
      console.log("error fetching api", mintsAlreadyMerging);
    }

    const allMints = new Set<string>();
    const mintsMergingSet = new Set<string>();
    mintsAlreadyMerging.forEach((mint) => {
      allMints.add(mint);
      mintsMergingSet.add(mint);
    });
    walletMints.forEach((mint) => {
      allMints.add(mint);
    });

    this.mintsCurrentlyMerging = mintsMergingSet;

    Array.from(this.mintsSentToMergingTimes.keys()).forEach((mint) => {
      const currTime = Date.now();
      const time = this.mintsSentToMergingTimes.get(mint);
      if (!time) {
        return;
      }
      // If we added it less than two minutes ago manually through the website
      if (currTime - time < this.MILLIS_IN_MINUTE * 2) {
        this.mintsCurrentlyMerging.add(mint);
        allMints.add(mint);
        mintsMergingSet.add(mint);
      }
    });

    const tokens: TokenMetadata[] =
      await TokenMetadataService.getMetadataForTokens(
        Array.from(allMints.keys())
      );

    const res = MetaDataService.getSceneMetadataForTheseMints(tokens);
    this.initScenesHeldMetadata(res);
  }
}

const stateService = new StateService();
export { stateService as StateService };
