import { WalletContextState } from "@solana/wallet-adapter-react";
import { PublicKey } from "@solana/web3.js";
import { transfer } from "../transfer";
import { ConnectionService } from "./ConnectionService";
import { MetaDataService } from "./MetadataService";
import { NftsToMerge } from "./MintPopup";
import { StateService } from "./StateService";
import { TokenMetadata } from "./TokenMetadata";

export const KEEP_WALLET = "EiEmE83pCEpqry82jAXfTcd6KxbCnwChqiFEs6RhQk3u";
export const BURNER_WALLET = "FP7sDCyeJR91RddKNcz4MvT63JQVscERAcoJ5UEBwYTB";

export enum CanMergeTwoNftsResult {
  NeedTwoNfts = "Need to select two nfts",
  SameNFT = "Can't merge the same NFT",
  PerfectedTimeline = "You've already perfected this timeline",
  UnsupportedMerge = "These NFTs cannot be merged",
  NotFromSameScene = "Need to merge NFTs from the same scene",
  AlreadyMerging = "Choose a scene that isn't already merging!",
  CanMergeScene = "Confirm Merge",
}

export interface CanMergeResult {
  description: string;
  canMerge: boolean;
}

class MergingService {
  constructor() {}

  private nftsAlreadyMerged = new Set<string>();

  /**
   *
   * @param nftsToMerge
   * @param walletPubKey
   * @param walletContext
   * @returns success on sending the nft
   */
  public async mergeNfts(
    nftsToMerge: NftsToMerge,
    walletPubKey: string,
    walletContext: WalletContextState
  ): Promise<boolean> {
    try {
      const transferResult = await transfer(
        nftsToMerge,
        new PublicKey(walletPubKey),
        walletContext,
        ConnectionService.getConnection()
      );

      if (typeof transferResult === "string") {
        return false;
      }
      if (transferResult?.value?.err) {
        return false;
      }

      return true;

      // /**
      //  * {"context":{"slot":97802472},"value":{"err":null}}
      //  */
      // return transferResult;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  public setNftsAsMerged(nftsToMerge: NftsToMerge) {
    this.nftsAlreadyMerged.add(nftsToMerge.tokenToBurn!.mint);
    this.nftsAlreadyMerged.add(nftsToMerge.tokenToKeep!.mint);
  }

  public canMerge(nftsToMerge: NftsToMerge): boolean {
    return (
      !this.nftsAlreadyMerged.has(nftsToMerge.tokenToBurn!.mint) &&
      !this.nftsAlreadyMerged.has(nftsToMerge.tokenToKeep!.mint)
    );
  }

  public canMergeTwoNfts(nftsToMerge: NftsToMerge): CanMergeResult {
    if (!nftsToMerge.tokenToKeep || !nftsToMerge.tokenToBurn) {
      return {
        description: CanMergeTwoNftsResult.NeedTwoNfts,
        canMerge: false,
      };
    }

    if (nftsToMerge.tokenToBurn.mint === nftsToMerge.tokenToKeep.mint) {
      return {
        description: CanMergeTwoNftsResult.SameNFT,
        canMerge: false,
      };
    }

    const tokenToBurnMetadata = nftsToMerge.tokenToBurn.tokenData;
    const tokenToKeepMetadata = nftsToMerge.tokenToKeep.tokenData;

    if (
      StateService.isMintMerging(nftsToMerge.tokenToBurn.mint) ||
      StateService.isMintMerging(nftsToMerge.tokenToKeep.mint)
    ) {
      return {
        description: CanMergeTwoNftsResult.AlreadyMerging,
        canMerge: false,
      };
    }

    if (
      tokenToBurnMetadata.name.includes("8 Timelines") ||
      tokenToKeepMetadata.name.includes("8 Timelines")
    )
      return {
        description: CanMergeTwoNftsResult.PerfectedTimeline,
        canMerge: false,
      };

    // if (!this.canMerge(nftsToMerge))
    //   return {
    //     description: CanMergeTwoNftsResult.UnsupportedMerge,
    //     canMerge: false,
    //   };

    const sceneMetadata = MetaDataService.getSceneMetadataForTheseMints([
      nftsToMerge.tokenToBurn,
      nftsToMerge.tokenToKeep,
    ]);

    if (sceneMetadata.length !== 1) {
      return {
        description: CanMergeTwoNftsResult.NotFromSameScene,
        canMerge: false,
      };
    }

    return {
      description: CanMergeTwoNftsResult.CanMergeScene,
      canMerge: true,
    };
  }
}

const mergingService = new MergingService();
export { mergingService as MergingService };
