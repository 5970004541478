import { ThemeProvider } from "@material-ui/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import Vimeo from "@u-wave/react-vimeo";
import { useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import { SceneView } from "./merging/SceneView";
import theme from "./modules/theme";
import AppAppBar from "./modules/views/AppAppBar";
import ProductHero from "./modules/views/ProductHero";

const App = () => {

  useEffect(() => {
    document.title = 'Infinity Labs Merging'
  })

  return (
    <ThemeProvider theme={theme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <Router>
        <AppAppBar />
        <SceneView/>
      </Router>
    </ThemeProvider>
  );
};

export default App;
