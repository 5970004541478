import React, { memo, useCallback, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { HeldScenesMetadata } from "./MetadataService";
import { TokenData, TokenMetadata } from "./TokenMetadata";
import {
  Button,
  CardMedia,
  CircularProgress,
  Container,
  FormControl,
  Grid,
  InputLabel,
  Paper,
  Select,
  Snackbar,
} from "@material-ui/core";
import state from "@project-serum/anchor/dist/program/namespace/state";
import { MergingService } from "./MergingService";
import { WalletContextState } from "@solana/wallet-adapter-react";
import { Alert } from "@material-ui/lab";
import { StateService } from "./StateService";
import { MetaDataService } from "./MetadataService";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      backgroundColor: "black",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "100%",
    },
    confirmPaper: {
      backgroundColor: "black",
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      width: "50%",
    },
    confirmText: {
      color: "white !important",
    },
    headerText: {
      textAlign: "center",
    },
    media: {
      objectFit: "cover",
      maxWidth: "400px",
      maxHeight: "400px",
      width: "100vw", //this gives me full width
      height: "calc(50vw)",
    },
    margin: {
      margin: theme.spacing(1),
    },
  })
);

export interface IMintPopupProps {
  heldSceneMetadata: HeldScenesMetadata | null;
  setScenesHeldMetadata: (sceneHeldMetadata: HeldScenesMetadata | null) => void;
  walletPubKey: string;
  walletState: WalletContextState | null;
  //   isOpen: boolean;
  //   setOpen: () => void;
}

const useConfirmScreenStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  })
);

const MintPopup = ({
  heldSceneMetadata,
  setScenesHeldMetadata,
  walletPubKey,
  walletState,
}: IMintPopupProps): JSX.Element => {
  const isOpen = heldSceneMetadata != null;
  const [nftToBurn, setNftToBurn] = useState<TokenMetadata | null>(null);
  const [nftToKeep, setNftToKeep] = useState<TokenMetadata | null>(null);
  const [confirmModalOpen, setConfirmModalOpen] = React.useState(false);
  const [isSendingNft, setIsSendingNft] = useState<boolean>();
  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });
  const classes = useStyles({ ratio: 1 });

  if (!isOpen) {
    if (nftToKeep) {
      setNftToKeep(null);
    }
    if (nftToBurn) {
      setNftToBurn(null);
    }
  }

  const handleClose = () => {
    setConfirmModalOpen(false);
  };

  const mintDisplayName = useCallback((tokenData: TokenMetadata | null) => {
    if (!tokenData) {
      return "";
    }
    if (tokenData.tokenData.name.toLocaleLowerCase().includes("timelines")) {
      return (
        tokenData.tokenData.name +
        " : original timeline #" +
        MetaDataService.getTimelineFromMint(tokenData.mint)
      );
    } else {
      return tokenData.tokenData.name;
    }
  }, []);

  let confirmScreenModal = confirmModalOpen ? (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={confirmModalOpen}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={confirmModalOpen}>
          <div className={classes.confirmPaper}>
            <Container>
              <h2 className={classes.headerText} id="transition-modal-title">
                Confirm Merging
              </h2>
            </Container>

            <p className={classes.confirmText}>
              {" "}
              Keeping {mintDisplayName(nftToKeep)}
            </p>
            <p className={classes.confirmText}>
              {" "}
              Burning {mintDisplayName(nftToBurn)}
            </p>

            <Button
              variant="outlined"
              size={"large"}
              onClick={async () => {
                if (walletState) {
                  // Show loading screen, then update urls?
                  setConfirmModalOpen(false);
                  setIsSendingNft(true);
                  let canMergeNFts = MergingService.canMergeTwoNfts({
                    tokenToBurn: nftToBurn,
                    tokenToKeep: nftToKeep,
                  });
                  if (!canMergeNFts.canMerge) {
                    setAlertState({
                      open: true,
                      message:
                        "You've selected a scene that has already merged!",
                      severity: "error",
                    });
                    // Error sending NFT
                    setIsSendingNft(false);
                    return;
                  }
                  const mergeNftResultPromise = MergingService.mergeNfts(
                    {
                      tokenToBurn: nftToBurn,
                      tokenToKeep: nftToKeep,
                    },
                    walletPubKey,
                    walletState
                  );
                  try {
                    const mergeNftResult = await mergeNftResultPromise;
                    if (mergeNftResult) {
                      StateService.sentNftsToMerge({
                        tokenToBurn: nftToBurn,
                        tokenToKeep: nftToKeep,
                      });
                      MergingService.setNftsAsMerged({
                        tokenToBurn: nftToBurn,
                        tokenToKeep: nftToKeep,
                      });
                      // Update urls
                      setAlertState({
                        open: true,
                        message:
                          "Sent! The merged NFT will be sent back in a few minutes!",
                        severity: "success",
                      });
                    } else {
                      setAlertState({
                        open: true,
                        message:
                          "Error while trying to send transaction. Refresh and try again?",
                        severity: "error",
                      });
                      // Error sending NFT
                    }
                  } catch (error) {
                  } finally {
                    setIsSendingNft(false);
                  }
                }
              }}
            >
              CONFIRM
            </Button>
          </div>
        </Fade>
      </Modal>
    </div>
  ) : (
    <span />
  );

  const canMergeResult = MergingService.canMergeTwoNfts({
    tokenToBurn: nftToBurn,
    tokenToKeep: nftToKeep,
  });

  const SelectScenes = (
    <div>
      <SelectMergeScenes
        heldSceneMetadata={heldSceneMetadata}
        setTokenToBurn={setNftToBurn}
        setTokenToKeep={setNftToKeep}
      />
      <Button
        variant="outlined"
        size={"large"}
        onClick={() => {
          setConfirmModalOpen(true);
        }}
        disabled={!canMergeResult.canMerge}
      >
        {canMergeResult.canMerge ? "MERGE" : canMergeResult.description}
      </Button>
    </div>
  );

  const SnackBar = (
    <Snackbar
      open={alertState.open}
      autoHideDuration={6000}
      onClose={() => setAlertState({ ...alertState, open: false })}
    >
      <Alert
        variant={"filled"}
        onClose={() => setAlertState({ ...alertState, open: false })}
        severity={alertState.severity}
      >
        {alertState.message}
      </Alert>
    </Snackbar>
  );

  const SentNftLoading = (
    <Container style={{ alignContent: "center" }}>
      <Grid container spacing={0} direction="column" alignItems="center">
        <Grid item xs={7}>
          <h4 className={classes.headerText} id="transition-modal-title">
            Waiting for transaction confirmation
          </h4>
        </Grid>
        <Grid item xs={7}>
          <CircularProgress />
        </Grid>
      </Grid>
    </Container>
  );

  const popupContent = heldSceneMetadata ? (
    <div className={classes.paper}>
      <Container>
        <h2 className={classes.headerText} id="transition-modal-title">
          Pick NFTs from Scene #{heldSceneMetadata.scene} to merge
        </h2>
      </Container>
      <Grid container spacing={2}>
        <Grid item sm={12} md={5}>
          <Container>
            <CardMedia
              className={classes.media}
              image={heldSceneMetadata.image}
            />
          </Container>
        </Grid>
        <Grid item sm={12} md={7}>
          <Container>{isSendingNft ? SentNftLoading : SelectScenes}</Container>
        </Grid>
      </Grid>
    </div>
  ) : (
    <div></div>
  );
  return (
    <div>
      {confirmScreenModal}
      <Modal
        aria-labelledby="merging-modal"
        className={classes.modal}
        open={isOpen}
        onClose={() => setScenesHeldMetadata(null)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={isOpen}>{popupContent}</Fade>
      </Modal>
      {SnackBar}
    </div>
  );
};

export interface NftsToMerge {
  tokenToKeep: TokenMetadata | null;
  tokenToBurn: TokenMetadata | null;
}

export interface ISelectMergeScenesProps {
  heldSceneMetadata: HeldScenesMetadata | null;
  setTokenToBurn: (tokenToBurn: TokenMetadata | null) => void;
  setTokenToKeep: (tokenToKeep: TokenMetadata | null) => void;
}

const SelectMergeScenesStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
  })
);

const SelectMergeScenes = ({
  heldSceneMetadata,
  setTokenToBurn,
  setTokenToKeep,
}: ISelectMergeScenesProps): JSX.Element => {
  const mintDisplayName = useCallback((tokenData: TokenMetadata) => {
    if (tokenData.tokenData.name.toLocaleLowerCase().includes("timelines")) {
      return (
        tokenData.tokenData.name +
        " : original timeline #" +
        MetaDataService.getTimelineFromMint(tokenData.mint)
      );
    } else {
      return tokenData.tokenData.name;
    }
  }, []);

  if (!heldSceneMetadata) {
    return <span />;
  }

  const classes = SelectMergeScenesStyles();

  const mergingTokenMetadata = heldSceneMetadata.mints.filter(
    (tokenMetadata) => {
      return (
        StateService.isMintMerging(tokenMetadata.mint) &&
        !StateService.isMintBurned(tokenMetadata.mint)
      );
    }
  );

  let notMergingTokenMetadata = heldSceneMetadata.mints.filter(
    (tokenMetadata) => {
      return (
        !StateService.isMintMerging(tokenMetadata.mint) &&
        !StateService.isMintBurned(tokenMetadata.mint)
      );
    }
  );

  // Change names of any merged timelines
  // notMergingTokenMetadata.forEach((TokenMetadataService) => {
  //   if (StateService.isMintMerged(TokenMetadataService.mint)) {
  //     const partOne = TokenMetadataService.tokenData.name.split(" | ")[0];

  //     TokenMetadataService.tokenData.name = partOne + " | 2 Timelines";
  //   }
  // });

  return (
    <div>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple">
          NFT to keep
        </InputLabel>
        <Select
          native
          label="NFT to keep"
          onChange={(event) => {
            if (!event) {
              return;
            }
            if (!event.target.value) {
              return setTokenToKeep(null);
            }

            setTokenToKeep(JSON.parse(event.target.value as string));
          }}
          inputProps={{
            name: "NFT to keep",
            id: "outlined-age-native-simple",
          }}
        >
          <option aria-label="None" value="" />
          <optgroup label="NFTs in Wallet">
            {notMergingTokenMetadata.map((mint) => {
              return (
                <option
                  key={mint.mint + mint.tokenData.name}
                  value={JSON.stringify(mint)}
                >
                  {mintDisplayName(mint)}
                </option>
              );
            })}
          </optgroup>
          <optgroup label="Currently Merging NFTs">
            {mergingTokenMetadata.map((mint) => {
              return (
                <option
                  key={mint.mint + mint.tokenData.name}
                  value={JSON.stringify(mint)}
                >
                  {mintDisplayName(mint) + " (merging in progress)"}
                </option>
              );
            })}
          </optgroup>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={classes.formControl}>
        <InputLabel htmlFor="outlined-age-native-simple">
          NFT to burn
        </InputLabel>
        <Select
          native
          onChange={(event) => {
            if (!event) {
              return;
            }

            if (!event.target.value) {
              return setTokenToBurn(null);
            }

            setTokenToBurn(JSON.parse(event.target.value as string));
          }}
          label="NFT to burn"
          inputProps={{
            name: "NFT to burn",
            id: "outlined-age-native-simple",
          }}
        >
          <option aria-label="None" value="" />
          <optgroup label="NFTs in Wallet">
            {notMergingTokenMetadata.map((mint) => {
              return (
                <option
                  key={mint.mint + mint.tokenData.name}
                  value={JSON.stringify(mint)}
                >
                  {mintDisplayName(mint)}
                </option>
              );
            })}
          </optgroup>
          <optgroup label="Currently Merging NFTs">
            {mergingTokenMetadata.map((mint) => {
              return (
                <option
                  key={mint.mint + mint.tokenData.name}
                  value={JSON.stringify(mint)}
                >
                  {mintDisplayName(mint) + " (merging in progress)"}
                </option>
              );
            })}
          </optgroup>
        </Select>
      </FormControl>
    </div>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

export { MintPopup };
