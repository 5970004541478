import { BinaryReader, BinaryWriter, deserializeUnchecked } from "borsh";
import { PublicKey, AccountInfo } from "@solana/web3.js";
import { ConnectionService } from "./ConnectionService";

class TokenMetadataService {
  /**
   * Get onchain token metadata for these mints
   * @param keys
   * @returns
   */
  public async getMetadataForTokens(keys: string[]): Promise<TokenMetadata[]> {
    const mints: TokenMetadata[] = [];
    const tokenMetadataArray = await this.getMetadata(keys);
    let index = 0;
    for (const tokenMetadata of tokenMetadataArray) {
      mints.push({
        tokenData: {
          ...tokenMetadata.data,
          //@ts-ignore
          creators: tokenMetadata.data.creators.map((d: any) => {
            return {
              share: d.share,
              address: new PublicKey(d.address).toBase58(),
              verified: !!d.verified,
            };
          }),
        },
        mint: keys[index],
      });
      index++;
    }

    return mints;
  }

  private decodeMetadata(buffer: Buffer): Metadata {
    const metadata = deserializeUnchecked(
      METADATA_SCHEMA,
      Metadata,
      buffer
    ) as Metadata;

    metadata.data.name = metadata.data.name.replace(/\0/g, "");
    metadata.data.symbol = metadata.data.symbol.replace(/\0/g, "");
    metadata.data.uri = metadata.data.uri.replace(/\0/g, "");
    metadata.data.name = metadata.data.name.replace(/\0/g, "");
    return metadata;
  }

  private async getMetadata(pubkeys: string[]): Promise<Metadata[]> {
    const metadata: Metadata[] = [];
    // process all of the accounts
    const paginationLimit = 99;
    const accountInfos: AccountInfo<Buffer>[] = [];
    for (let x = 0; x <= pubkeys.length; x += paginationLimit) {
      const pubkeysToProcess = pubkeys.slice(x, x + paginationLimit);
      const metadataKeysToProcess = [];
      for (const pubkey of pubkeysToProcess) {
        metadataKeysToProcess.push(
          new PublicKey(await this.getMetadataKey(pubkey))
        );
      }
      const accountInfoRes =
        await ConnectionService.getConnection().getMultipleAccountsInfo(
          metadataKeysToProcess
        );
      if (accountInfoRes && accountInfoRes.length !== 0) {
        const notNullAccountInfo = accountInfoRes as AccountInfo<Buffer>[];
        accountInfos.push(...notNullAccountInfo);
      }
    }

    try {
      // Just a fancy getAccountInfo

      for (const accountInfo of accountInfos) {
        if (accountInfo.data.length > 0) {
          metadata.push(this.decodeMetadata(accountInfo.data));
        }
      }
    } catch (e) {
      console.log(e);
    }

    return metadata;
  }

  private async getMetadataKey(
    tokenMint: StringPublicKey
  ): Promise<StringPublicKey> {
    const PROGRAM_IDS = programIds();

    return (
      await findProgramAddress(
        [
          Buffer.from(METADATA_PREFIX),
          toPublicKey(PROGRAM_IDS.metadata).toBuffer(),
          toPublicKey(tokenMint).toBuffer(),
        ],
        toPublicKey(PROGRAM_IDS.metadata)
      )
    )[0];
  }
}

const tokenMetadataService = new TokenMetadataService();
export { tokenMetadataService as TokenMetadataService };

export interface TokenData {
  name: string;
  symbol: string;
  uri: string;
  sellerFeeBasisPoints: number;
  creators: Creator[];
}

export interface TokenMetadata {
  tokenData: TokenData;
  mint: string;
}

// Converting this to import will not make it work for reasons
// eslint-disable-next-line @typescript-eslint/no-var-requires
const base58 = require("bs58");
export const METADATA_PREFIX = "metadata";

export class Creator {
  address: PublicKey;
  verified: boolean;
  share: number;

  constructor(args: { address: PublicKey; verified: boolean; share: number }) {
    this.address = args.address;
    this.verified = args.verified;
    this.share = args.share;
  }
}

export enum MetadataKey {
  Uninitialized = 0,
  MetadataV1 = 4,
  EditionV1 = 1,
  MasterEditionV1 = 2,
  MasterEditionV2 = 6,
  EditionMarker = 7,
}

export class Data {
  name: string;
  symbol: string;
  uri: string;
  sellerFeeBasisPoints: number;
  creators: Creator[] | null;
  constructor(args: {
    name: string;
    symbol: string;
    uri: string;
    sellerFeeBasisPoints: number;
    creators: Creator[] | null;
  }) {
    this.name = args.name;
    this.symbol = args.symbol;
    this.uri = args.uri;
    this.sellerFeeBasisPoints = args.sellerFeeBasisPoints;
    this.creators = args.creators;
  }
}

export class Metadata {
  key: MetadataKey;
  updateAuthority: PublicKey;
  mint: PublicKey;
  data: Data;
  primarySaleHappened: boolean;
  isMutable: boolean;
  masterEdition?: PublicKey;
  edition?: PublicKey;
  constructor(args: {
    updateAuthority: PublicKey;
    mint: PublicKey;
    data: Data;
    primarySaleHappened: boolean;
    isMutable: boolean;
    masterEdition?: PublicKey;
  }) {
    this.key = MetadataKey.MetadataV1;
    this.updateAuthority = args.updateAuthority;
    this.mint = args.mint;
    this.data = args.data;
    this.primarySaleHappened = args.primarySaleHappened;
    this.isMutable = args.isMutable;
  }
}

export type StringPublicKey = string;

export const TOKEN_PROGRAM_ID = new PublicKey(
  "TokenkegQfeZyiNwAJbNbGKPFXCWuBvf9Ss623VQ5DA"
);

export const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new PublicKey(
  "ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL"
);

export const BPF_UPGRADE_LOADER_ID = new PublicKey(
  "BPFLoaderUpgradeab1e11111111111111111111111"
);

export const MEMO_ID = new PublicKey(
  "MemoSq4gqABAXKb96qnH8TysNcWxMyWCqXgDLGmfcHr"
);

export const METADATA_PROGRAM_ID =
  "metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s" as StringPublicKey;

export const VAULT_ID =
  "vau1zxA2LbssAUEF7Gpw91zMM1LvXrvpzJtmZ58rPsn" as StringPublicKey;

export const AUCTION_ID =
  "auctxRXPeJoc4817jDhf4HbjnhEcr1cCXenosMhK5R8" as StringPublicKey;

export const METAPLEX_ID =
  "p1exdMJcjVao65QdewkaZRUnU6VPSXhus9n2GzWfh98" as StringPublicKey;

export const SYSTEM = new PublicKey("11111111111111111111111111111111");

export const METADATA_SCHEMA = new Map<any, any>([
  [
    Data,
    {
      kind: "struct",
      fields: [
        ["name", "string"],
        ["symbol", "string"],
        ["uri", "string"],
        ["sellerFeeBasisPoints", "u16"],
        ["creators", { kind: "option", type: [Creator] }],
      ],
    },
  ],
  [
    Creator,
    {
      kind: "struct",
      fields: [
        ["address", [32]],
        ["verified", "u8"],
        ["share", "u8"],
      ],
    },
  ],
  [
    Metadata,
    {
      kind: "struct",
      fields: [
        ["key", "u8"],
        ["updateAuthority", [32]],
        ["mint", [32]],
        ["data", Data],
        ["primarySaleHappened", "u8"],
        ["isMutable", "u8"],
      ],
    },
  ],
]);

export const PubKeysInternedMap = new Map<string, PublicKey>();

export const toPublicKey = (key: string | PublicKey) => {
  if (typeof key !== "string") {
    return key;
  }

  let result = PubKeysInternedMap.get(key);
  if (!result) {
    result = new PublicKey(key);
    PubKeysInternedMap.set(key, result);
  }

  return result;
};

export interface PublicKeyStringAndAccount<T> {
  pubkey: string;
  account: AccountInfo<T>;
}

export const findProgramAddress = async (
  seeds: (Buffer | Uint8Array)[],
  programId: PublicKey
) => {
  const result = await PublicKey.findProgramAddress(seeds, programId);

  return [result[0].toBase58(), result[1]] as [string, number];
};

export const programIds = () => {
  return {
    token: TOKEN_PROGRAM_ID,
    associatedToken: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
    bpf_upgrade_loader: BPF_UPGRADE_LOADER_ID,
    system: SYSTEM,
    metadata: METADATA_PROGRAM_ID,
    memo: MEMO_ID,
    vault: VAULT_ID,
    auction: AUCTION_ID,
    metaplex: METAPLEX_ID,
  };
};

const extendBorsh = () => {
  (BinaryReader.prototype as any).readPubkey = function () {
    const reader = this as unknown as BinaryReader;
    const array = reader.readFixedArray(32);
    return new PublicKey(array);
  };

  (BinaryWriter.prototype as any).writePubkey = function (value: any) {
    const writer = this as unknown as BinaryWriter;
    writer.writeFixedArray(value.toBuffer());
  };

  (BinaryReader.prototype as any).readPubkeyAsString = function () {
    const reader = this as unknown as BinaryReader;
    const array = reader.readFixedArray(32);
    return base58.encode(array) as StringPublicKey;
  };

  (BinaryWriter.prototype as any).writePubkeyAsString = function (
    value: StringPublicKey
  ) {
    const writer = this as unknown as BinaryWriter;
    writer.writeFixedArray(base58.decode(value));
  };
};

extendBorsh();
