import { Button, CircularProgress, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import * as anchor from "@project-serum/anchor";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";
import { useWallet } from "@solana/wallet-adapter-react";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import { useCallback, useEffect, useState } from "react";
import Countdown from "react-countdown";
import styled from "styled-components";
import { MetaDataService } from "../merging/MetadataService";
import { IWalletConnection } from "../merging/SceneView";
import { shortenAddress, transfer } from "../transfer";

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  txTimeout: number;
  onWalletConnect: (walletConnection: IWalletConnection) => void;
}

const Wallet = (props: HomeProps) => {
  const [solBalance, setSolBalance] = useState<number>();
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const onWalletConnect = props.onWalletConnect;

  const wallet = useWallet();

  const onDisconnect = useCallback(async () => {
    await wallet.disconnect();
    onWalletConnect({
      walletPubKey: "",
      isWalletConnected: false,
      walletState: null,
    });
  }, [onWalletConnect, wallet]);

  // Use effect will re-occur on renders
  useEffect(() => {
    // console.log("update balance called: " + wallet.publicKey);

    async function fetchMyAPI() {
      if (wallet.publicKey && wallet.connected) {
        onWalletConnect({
          walletPubKey: wallet.publicKey.toBase58(),
          isWalletConnected: true,
          walletState: wallet,
        });
      }
    }
    fetchMyAPI();
    return () => {};
  }, [onWalletConnect, props.connection, wallet, wallet.publicKey]);

  return (
    <div>
      {!wallet.connected ? (
        <ConnectButton>Connect Wallet</ConnectButton>
      ) : (
        <MintButton
          onClick={onDisconnect}
          variant="outlined"
          size="large"
          style={{ marginTop: "14px" }}
        >
          disconnect
        </MintButton>
      )}
    </div>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

export default Wallet;
