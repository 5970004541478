import { makeStyles } from "@material-ui/core/styles";
import * as anchor from "@project-serum/anchor";
import {
  WalletAdapter,
  WalletAdapterNetwork,
} from "@solana/wallet-adapter-base";
import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";
import {
  getLedgerWallet,
  getPhantomWallet,
  getSlopeWallet,
  getSolflareWallet,
  getSolletExtensionWallet,
  getSolletWallet,
} from "@solana/wallet-adapter-wallets";
import { clusterApiUrl } from "@solana/web3.js";
import { memo, useMemo } from "react";
import { IWalletConnection } from "../merging/SceneView";
import Wallet from "./Wallet";

const REACT_APP_CANDY_MACHINE_CONFIG =
  "HNoCwy7eV8uiokspSYzeJStzDiVtiEsFQTFQayUAxKnb";
const REACT_APP_CANDY_MACHINE_ID =
  "8H65txacY9uvzzbrhY1gpVxkRLX8rweqqe6FQPnTv8JW";
const REACT_APP_TREASURY_ADDRESS =
  "acKmThZiaoZkGnr1MNjVSp2gBsA4GkK8phdrRGdjGjH";
const REACT_APP_SOL_WALLET = "BnRLyz8RfULs4phucV9EzmDNkMNg96D5yQtg7cZ9BBZo";
const REACT_APP_SPL_WALLET = "Fm6yGV3HiHnmLarmdow7vS9NWigM6Jcw9LidD6qPpfKr";
const REACT_APP_CANDY_START_DATE = "1632864600000";

const REACT_APP_SOLANA_NETWORK =
  process.env.NODE_ENV === "production" ? "mainnet-beta" : "devnet";
const REACT_APP_SOLANA_RPC_HOST =
  process.env.NODE_ENV === "production"
    ? "https://solana-mainnet.g.alchemy.com/v2/hnIvp_uv5_OBS02WjXajVZI_PvdK_0VB"
    : "https://api.devnet.solana.com";

const treasury = new anchor.web3.PublicKey(REACT_APP_TREASURY_ADDRESS!);
const sol_wallet = new anchor.web3.PublicKey(REACT_APP_SOL_WALLET!);
const spl_wallet = new anchor.web3.PublicKey(REACT_APP_SPL_WALLET!);

const config = new anchor.web3.PublicKey(REACT_APP_CANDY_MACHINE_CONFIG!);

const network = REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

interface IWalletAdapterProps {
  onWalletConnect: (walletConnection: IWalletConnection) => void;
}

const WalletWrapper = memo(
  ({ onWalletConnect }: IWalletAdapterProps): JSX.Element => {
    const endpoint = useMemo(() => clusterApiUrl(network), []);

    const wallets = useMemo(
      () => [
        getPhantomWallet(),
        getSolflareWallet(),
        getSlopeWallet(),
        getLedgerWallet(),
        getSolletWallet({ network }),
        getSolletExtensionWallet({ network }),
      ],
      []
    );

    return (
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets} autoConnect>
          <WalletDialogProvider>
            <Wallet
              config={config}
              connection={connection}
              txTimeout={txTimeout}
              onWalletConnect={onWalletConnect}
            />
          </WalletDialogProvider>
        </WalletProvider>
      </ConnectionProvider>
    );
  }
);

export { WalletWrapper };
